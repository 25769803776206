import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

export const cards = ({
  headcount,
  admissions,
  resignations,
}: {
  headcount: number;
  admissions: number;
  resignations: number;
}) => [
  {
    id: "headcount",
    title: "Headcount atual",
    value: headcount,
    icon: "IconUsers" as IconTypes,
    explanation: "Total de pessoas com status ativo na empresa.",
  },
  {
    id: "admissions",
    title: "Admissões no mês",
    value: admissions,
    icon: "IconUserPlus" as IconTypes,
    explanation:
      "Total de pessoas admitidas no mês atual pelo módulo de Fluxo de Pessoas.",
  },
  {
    id: "resignations",
    title: "Desligamentos no mês",
    value: resignations,
    icon: "IconUserMinus" as IconTypes,
    explanation:
      "Total de pessoas desligadas no mês atual pelo módulo de Fluxo de Pessoas.",
  },
];

export const graphs = (
  {
    headcountEvolution = [],
    turnover = [],
    averageServiceTime,
  }: {
    headcountEvolution: {
      name: string;
      bar1: number;
      bar2: number;
      line: number;
    }[];
    averageServiceTime: { name: string; amt: number }[];
    turnover: { name: string; line: number; defaultLine?: number }[];
  },
  filters: {
    headcountEvolution: {
      dateTo?: string;
      dateFrom?: string;
    };
    turnover: {
      dateTo?: string;
      dateFrom?: string;
    };
  }
) => [
  {
    id: "headcountEvolution",
    info: {
      title: "Evolução do Headcount",
      explanation:
        "Análise de pessoas com status ativo por mês, do número de admitidos e de desligados.",
      metric: "",
    },
    data: headcountEvolution,
    settings: {
      bar1Name: "Admitidos",
      bar2Name: "Desligados",
      line: "Total",
      bar1Color: "#F20D7A",
      bar2Color: "#FF9AC9",
      lineColor: "#F20D7A",
      lineIsPercentage: false,
    },
    filters: filters.headcountEvolution,
  },
  {
    id: "turnover",
    info: {
      title: "Turnover",
      metric: "",
      explanation:
        "Análise da rotatividade de colaboradores. Os índices mensais são calculados a partir da fórmula [número de contratados + número de desligados)/2/(headcount do mês anterior)]x100. Caso não haja admissão ou desligamento, o indicador de turnover estará zerado.",
    },
    data: turnover,
    settings: {
      line: "Turnover",
      lineColor: "#F20D7A",
      isPercentage: true,
    },
    filters: filters.turnover,
  },
  {
    id: "",
    info: {
      title: "Tempo de casa",
      metric: "",
      explanation:
        "Contagem de quanto tempo os colaboradores com status ativo estão na empresa.",
    },
    settings: {
      name: "quantidade",
      color: "#FE2B8F",
      showAll: false,
    },
    data: averageServiceTime,
  },
];
